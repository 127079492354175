<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="41"
    height="41"
    viewBox="0 0 41 41"
  >
    <defs>
      <filter id="vv5mhc5a4a" color-interpolation-filters="auto">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g
          filter="url(#vv5mhc5a4a)"
          transform="translate(-778.000000, -313.000000) translate(610.000000, 135.000000)"
        >
          <g>
            <path
              d="M0 0H41V41H0z"
              transform="translate(168.000000, 178.000000)"
            />
            <path
              d="M0 0H41V41H0z"
              transform="translate(168.000000, 178.000000)"
            />
            <path
              stroke="#323232"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.68"
              d="M10.25 17.083l-3.71-3.71c-1.887-1.886-1.887-4.946 0-6.833h0c.906-.906 2.135-1.415 3.417-1.415 1.281 0 2.51.51 3.417 1.415l3.71 3.71M8.542 15.375L15.375 8.542M30.75 23.917l2.92 2.92c.476.476.801 1.084.934 1.745l1.254 6.27c.056.28-.032.57-.234.772-.202.202-.491.29-.771.234l-6.271-1.254c-.662-.133-1.269-.458-1.746-.935l-2.92-2.919M30.34 18.382l-16.98 16.98c-.685.684-1.794.684-2.478 0l-5.244-5.244c-.684-.684-.684-1.793 0-2.477L27.64 5.638c.684-.684 1.793-.684 2.477 0l5.245 5.244c.683.684.683 1.793 0 2.477l-5.023 5.023zM25.832 11.337L23.887 9.391M21.855 17.023L19.055 14.223M16.168 21L14.223 19.055M12.191 26.686L9.391 23.887"
              transform="translate(168.000000, 178.000000)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconConstructionToolsPencil",
};
</script>

<style></style>
